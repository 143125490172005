import React from 'react';
import './home/home.css';
//import Fish1 from './fish1.png';
//import Fish2 from './fish2.png';
//import Fish3 from './fish3.png';

function EasterEgg() {
    return (
        <div className="Aquarium">

        </div>
    );
}

export default EasterEgg;