import React from "react";

const AMFooter = () => {
    return (
        <footer>
            <p style={{fontSize: '0.75em'}}>&copy; 2024 TeamTetra. All rights reserved.</p>
        </footer>
    )
};

export default AMFooter;